import React from "react";
import { messagera } from "../../../../assets";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
const Header = () => {
  const history = useHistory();
  const { websiteConfig, statusGetWebsite } = useSelector(
    (state) => state.roles
  );
  return statusGetWebsite !== "succeeded" ? (
    <Skeleton
      width="38.87px"
      height="38.13px"
      variant="rectangular"
      style={{ borderRadius: "8px" }}
    />
  ) : (
    <div
      className="header-container"
      onClick={() => history.push("/messaging")}
    >
      <div className="logo-title-wrapper">
        <LazyLoadImage
          src={websiteConfig?.logo ? websiteConfig?.logo : messagera}
          alt="taki-chat-logo"
          id="messagesLogo"
        />
      </div>
    </div>
  );
};

export default Header;
