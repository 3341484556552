import React from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { AppBar, Toolbar, IconButton } from "@mui/material";
import { Iconify } from "../../components";
import data from "./../../utilities/constants";
import { useResponsive } from "../../hooks";
const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  width: "fit-content",
  left: 0,
  background: "transparent",
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: "58px",
  [theme.breakpoints.up("lg")]: {
    minHeight: "58px",
    padding: theme.spacing(0, 5),
  },
}));

const MessagingToolbarStyle = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    minHeight: data.APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));
export default function Header({ onOpenSidebar }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");

  return (
    !isDesktop &&
    (() => {
      return pathname.includes("/messaging") ? (
        <div>
          <MessagingToolbarStyle>
            <IconButton
              onClick={onOpenSidebar}
              sx={{ mr: 0, color: "text.primary", display: { lg: "none" } }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>
          </MessagingToolbarStyle>
        </div>
      ) : (
        <RootStyle>
          <ToolbarStyle>
            <IconButton
              onClick={onOpenSidebar}
              sx={{ mr: 0, color: "text.primary", display: { lg: "none" } }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>
          </ToolbarStyle>
        </RootStyle>
      );
    })()
  );
}
