import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { styled } from "@mui/material/styles";

const MainLayout = ({ children }) => {
  const RootStyle = styled("div")({
    display: "flex",
  });
  const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    minHeight: "100%",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
    },
  }));
  const [openSidebar, setOpenSidebar] = useState(false);
  return (
    <RootStyle>
      <Header onOpenSidebar={() => setOpenSidebar(true)} />
      <Sidebar
        isOpenSidebar={openSidebar}
        onCloseSidebar={() => setOpenSidebar(false)}
      />
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
};

export default MainLayout;
